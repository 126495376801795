























































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import CardItem from "@/components/CardItem.vue";
import { throttle } from "lodash";
import { Toast, Popup } from "vant";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import { UserSupplyDetailApi } from "@/api/user-supply.api";
import AddressForm from "@/components/AddressForm.vue";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";

@Component({
  components: {
    SyntheticDialog,
    CardItem,
    AddressForm,
    Popup,
  },
})
export default class CamiloCodeSupply extends Vue {
  @Prop() readonly voucher!: UserSupplyDetailApi;
  @Prop() readonly id!: number;
  Tips = true;
  async mounted() {
  }
  @Emit()
  againHandler() {}
  openTips() {
    this.againHandler();
  }
  throttleHandleOpenedEntity = throttle(
    this.handleOpenedEntity.bind(this),
    1500
  );
  confirmHandler() {
    this.throttleHandleOpened();
  }

  throttleHandleOpened = throttle(this.handleOpened.bind(this), 1500);
  throttleHandleSynthetic() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({ name: "Synthetic" });
  }
  toRecyle() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/recyle`);
    }
    this.$router.push({ name: "recyle" });
  }
  throttleHandlesell() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/hangsell?detail_id=${this.voucher.detail_id}`
      );
    }
    this.$router.push({
      name: "hangsell",
      query: {
        detail_id: String(this.voucher.detail_id),
      },
    });
  }
  get status() {
    return this.voucher.status;
  }

  get code_is_seal() {
    return this.voucher.is_seal;
  }

  get cover() {
    return this.voucher.cover;
  }

  get character() {
    return this.voucher.character.value;
  }

  get code_update_time() {
    return this.voucher.update_time;
  }

  get code_record() {
    return this.voucher.remark;
  }

  get title() {
    return this.voucher.title;
  }

  @Emit()
  handleSynthetic() {}

  @Emit()
  refreshData() {
    return;
  }

  handleOpenedEntity() {
    this.$router.replace({
      name: "ApplyDelivery",
      params: { id: String(this.id) },
    });
  }

  handleOpenedSupplyDetail() {
    this.$router.push({
      name: "MyDeliveryRecord",
      query: { time: String(getNow()) },
    });
  }

  async handleOpened() {
    const toast = Toast.loading({
      mask: true,
      message: "拆封中...",
    });
    // await VoucherApi.openedMyVoucher(this.id)
    //   .then(() => {
    //     toast.message = "拆封完毕";
    //     return this.refreshData();
    //   })
    //   .catch((err) => Toast.fail(err.message));
    // toast.close();
  }

  openNewURI(url: string) {
    window.open(url);
  }
}
