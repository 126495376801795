







































import { Component, Ref, Vue } from "vue-property-decorator";
import CamiloDetail from "@/components/CamiloDetail.vue";
import SyntheticDialog from "@/components/SyntheticDialog.vue";
import CamiloCode from "@/components/CamiloCodeSupply.vue";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";
import { Skeleton, Popup, Toast } from "vant";
import { path } from "animejs";
import { observer } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import { getDetail,openGoods,UserSupplyDetailApi,checkGoods } from "@/api/user-supply.api";

@Component({
  components: {
    SyntheticDialog,
    CamiloCode,
    CamiloDetail,
    Skeleton,
    Popup,
  },
})
export default class UserSupplyDetail extends Vue {
  @Ref("CamiloCode")
  private readonly CamiloCodeRef!: CamiloCode;
  id = Number(this.$route.params.id);
  globalState = getModule(GlobalStateStore);
  skeleton = true;
  Tips = false
  voucher: UserSupplyDetailApi | null = null;
  dialog = false;

  get flutter() {
    return InteractionLib.isApp();
  }
  againHandler() {
     this.Tips = true
  }
  async confirmHandler(){
    // this.CamiloCodeRef.confirmHandler()
    await openGoods(this.id,'')
    this.checkStatus()
    this.Tips = false
  }
  async checkStatus(){
    const checkRes = await checkGoods(this.id)
    if(checkRes.status == 1){
      this.checkStatus()
    }else if(checkRes.status == -1){
      InteractionLib.updateGoldBlindBoxStatus()
      Toast.fail('提货进行中');
      this.handleRefreshData()
    }else if(checkRes.status == 2){
      InteractionLib.updateGoldBlindBoxStatus()
      let indexs = '0'
      if(this.voucher?.type == 2){
        indexs = '1'
      }
      await this.$router.replace({ name: "mysupply", query: { num: "1",index:indexs } });
    }
  }
  get isLogin() {
    return Boolean(this.globalState.userToken);
  }

  async handleRefreshData(): Promise<void> {
    this.skeleton = true;
    this.voucher = await getDetail(this.id);
    if (this.voucher.is_seal != 0) {
      document.title = "我的补给";
    }
    this.skeleton = false;
  }
  mounted(): void {
    observer("refreshInit", () => this.handleRefreshData());
    InteractionLib.screenshotListen();
    this.handleRefreshData();
  }
  toShopDetail() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/sp-store/${this.voucher?.product_id}`);
    }
    this.$router.push({ path: `/sp-store/${this.voucher?.product_id}` });
  }
}
